<template>
  <div class="user-interfaces">
    <InformationBanner></InformationBanner>
    <router-view></router-view>
    <CookieBanner></CookieBanner>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/dashboards/user-interfaces.less"></style>

<script>
import InformationBanner from '@/components/InformationBanner'
import CookieBanner from '@/components/CookieBanner'

export default {
  components: {
    InformationBanner,
    CookieBanner
  },
  data() {
    return {}
  },
  methods: {}
}
</script>
